import { AxiosRequestConfig } from "axios";
import client from './client';

type TCityResponseItem = {
  altitude: number;
  country: string;
  state: string;
  latitude: number;
  longitude: number;
  name: string;
}

export type TCity = {
  altitude: number;
  country: string;
  state: string;
  latitude: string;
  longitude: string;
  name: string;
}

type TCityListParams = {
  name: string;
  limit: number
}

const list = (params: TCityListParams, config: AxiosRequestConfig = {}): Promise<Array<TCity>> => {
  return client.post<Array<TCityResponseItem>>('/cities', params, config)
    .then(response => response.data)
    .then(cities => {
      return cities.map((city) => {
        return {
          ...city,
          latitude: city.latitude.toString(),
          longitude: city.longitude.toString()
        }
      });
    });
};

export {
  list
};
